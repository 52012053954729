export const CATEGORY_RENT_SKY=[
    {
        title:'ВСЁ',
        // type:'all',
      
    },
    {
        title:'КОМПЛЕКТЫ',
        type:'set',
       
    },
    {
        title:'ЛЫЖЫ',
        type:'sky',
       
    },
    {
        title:'СНОУБОРД',
        type:'snowboard',
       
    },
    {
        title:'КУРТКИ',
        type:'jacket',
       
    },
    {
        title:'КОМБИНЕЗОН',
        type:'overall',
       
    },
    {
        title:'ШТАНЫ',
        type:'pants',
       
    },
    {
        title:'ПЕРЧАТКИ',
        type:'gloves',
       
    },
    {
        title:'ШЛЕМ',
        type:'helmet',
       
    },
    {
        title:'МАСКА',
        type:'mask',
       
    },
    {
        title:'ЗАЩИТА',
        type:'protection',
       
    },
]